<template>
  <div v-if="data" class="card card-statistics">
    <div class="card-header">
      <div class="card-title">Общая статистика</div>
    </div>
    <b-card-body class="statistics-body">
      <div class="row">
        <div class="col col-sm-6 col-xl-3 order-statistics">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="ShoppingCartIcon" />
              </b-avatar>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">{{ data.orders }}</h4>
              <b-card-text class="font-small-3 mb-0">Заказов</b-card-text>
            </div>
          </div>
        </div>
        <div class="col col-sm-6 col-xl-3 revenue-statistics">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <b-avatar size="48" variant="light-success">
                <font-awesome-icon icon="fa-solid fa-euro-sign" size="xl" />
              </b-avatar>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">{{ data.amount }}</h4>
              <b-card-text class="font-small-3 mb-0">Выручка</b-card-text>
            </div>
          </div>
        </div>
        <div class="col col-sm-6 col-xl-3 product-statistics">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <b-avatar size="48" variant="light-danger">
                <feather-icon size="24" icon="PackageIcon" />
              </b-avatar>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">{{ data.products }}</h4>
              <b-card-text class="font-small-3 mb-0">Продажи (шт)</b-card-text>
            </div>
          </div>
        </div>
        <div class="col col-sm-6 col-xl-3 customer-statistics">
          <div class="media">
            <div class="media-aside mr-2 align-self-start">
              <b-avatar size="48" variant="light-warning">
                <feather-icon size="24" icon="UsersIcon" />
              </b-avatar>
            </div>
            <div class="media-body my-auto">
              <h4 class="font-weight-bolder mb-0">{{ data.users }}</h4>
              <b-card-text class="font-small-3 mb-0">Клиентов</b-card-text>
            </div>
          </div>
        </div>
      </div>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCardText, BCardBody, BAvatar,
} from 'bootstrap-vue';

export default {
  components: {
    BCardText,
    BCardBody,
    BAvatar,
  },
  data() {
    return {
      data: {
        orders: 0,
        amount: 0,
        products: 0,
        users: 0,
      },
    };
  },
  async mounted() {
    await this.getStatistics();
  },
  methods: {
    async getStatistics() {
      this.data = (await this.$api.reports.statistics()).data;
    },
  },
};
</script>
