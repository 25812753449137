<template>
  <div
      class="app-content content"
      :class="[{'show-overlay': $store.state.app.shallShowOverlay}]"
  >
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <section id="dashboard-ecommerce">
          <div class="row">
            <div class="col-12">
              <ecommerce-statistics />
            </div>
          </div>
          <div class="row match-height">
            <div class="col-12">
              <ecommerce-profit-chart />
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

</template>

<script>

import EcommerceProfitChart from '@/views/dashboard/ecommerce/EcommerceProfitChart.vue';
import EcommerceStatistics from '@/views/dashboard/ecommerce/EcommerceStatistics.vue';

export default {
  components: { EcommerceProfitChart, EcommerceStatistics },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
