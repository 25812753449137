<template>
  <b-card
      v-if="data"
      class="card-tiny-line-stats"
      body-class="pb-50"
  >
    <h6>Заказы  за год по месяцам (шт)</h6>
    <h2 class="font-weight-bolder mb-1">{{ total }}</h2>
    <!-- chart -->
    <vue-apex-charts v-if="!loading" height="70" :options="statisticsProfit.chartOptions" :series="data.series" />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import { $themeColors } from '@themeConfig';

const $trackBgColor = '#EBEBEB';

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  async mounted() {
    const response = (await this.$api.orders.dashboard()).data;
    this.data.series[0].data = response.series;
    this.total = response.total;
    this.loading = false;
  },
  data() {
    return {
      loading: true,
      total: 0,
      data: {
        series: [
          {
            data: [45, 5, 78, 45, 25],
            name: 'Количество заказов',
          },
        ],
      },
      statisticsProfit: {
        chartOptions: {
          chart: {

            type: 'line',
            toolbar: {
              show: false,
            },
            zoom: {
              enabled: false,
            },
          },
          grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: false,
              },
            },
            padding: {
              top: -30,
              bottom: -10,
            },
          },
          stroke: {
            width: 3,
          },
          colors: [$themeColors.info],
          markers: {
            size: 2,
            colors: $themeColors.info,
            strokeColors: $themeColors.info,
            strokeWidth: 2,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.info,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 2,
            hover: {
              size: 3,
            },
          },
          xaxis: {
            labels: {
              show: true,
              style: {
                fontSize: '0px',
              },
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    };
  },
};
</script>
